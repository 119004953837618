.text-container {
  display: inline-block;
  vertical-align: middle;
  width: 90vw;
  margin: 0 auto;
  // opacity: 0;
  transition: opacity .6s ease, background-color .4s cubic-bezier(.59,.01,.28,1);
  will-change: opacity, background-color;
  position: relative;
  z-index: 10;

  @media (min-width: 768px) {
    width: 60vw;
  }

  &.visibility--active {
    opacity: 1;
  }

  h2, h4, h5, h6 {
    font-size: 30px;
    line-height: 1.1em;

    @media (max-width: 768px) {
      br {
        display: none;
      }
    }

    @media (min-width: 768px) {
      font-size: 4.6vw;
    }
  }

  &__title {
    font-size: 14px;
    text-transform: uppercase;
    margin: -24px 0 10px 0;
    line-height: 1em;

    @media (min-width: 768px) {
      margin: -32px 0 10px 0;
      font-size: 18px;
    }
  }

  p {
    margin: 0;
    font-size: 22px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 3vw;
    }

    a {
      display: inline-block;
      line-height: 0.8em;
      border-bottom: 1px solid;

      &:hover {
        border-bottom: 1px solid;
      }

      @media (min-width: 768px) {
        border-bottom: 3px solid;

        &:hover {
          border-bottom: 3px solid;
        }
      }
    }

    &.cta {
      margin-top: 3em;
      font-size: 18px;
      text-transform: uppercase;
      display: inline-block;
      transition: border .4s ease;
      border-bottom: 1px solid transparent;
      will-change: border;
    }
  }

  .categories-list {
    list-style: none;
    padding: 0;
    font-size: 30px;
    line-height: 1.4em;
    margin: 0;
    transition: background-color .3s ease;

    @media (min-width: 768px) {
      font-size: 4.6vw;
      line-height: 1.1em;
    }

    &__item {
      display: inline-block;
      line-height: .8em;
      color: inherit;
      border-bottom: 2px solid transparent;

      @media (min-width: 768px) {
        border-bottom: 4px solid transparent;
      }

      &:hover, &.is-active {
        border-bottom: 2px solid;

        @media (min-width: 768px) {
          border-bottom: 4px solid;
        }
      }
    }

    .touchevents .background-tint--done & {
      position: fixed;
      z-index: 8888;
      top: 68px;
      left: 0;
      width: 100%;
      padding: 20px 0;
      margin: 0;
      font-size: 22px;
      background-color: rgba(255,255,255,0.8);

      .categories-list__item {
        display: none;

        &.is-active {
          line-height: 1.1em;
          border: 0;
          margin: 0;
          display: block;
        }
      }
    }
  }
}