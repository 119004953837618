.brand-hero {
  position: relative;
  overflow: hidden;

  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .no-objectfit & {
    .video-wrapper {
      &__inner {
        position: relative;
        width: 100%;
        padding-bottom: 56%;
      }
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;

    .no-objectfit & {
      width: auto;
      min-height: 100%;
    }
  }
}