/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
  transition: opacity 0s !important;
}

#nprogress .bar {
  background: black;

  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  width: 100%;
  height: 5px;
}

