body[data-page="projects"] {
  &.background-tint--done {
    header {
      background-color: rgba(255, 255, 255, 0.8);

      .touchevents & {
        border-bottom: 1px solid black;
      }
    }
  }
}