.image-container {

  @media (max-width: 768px) {
    padding: 0 !important;
  }

  &__inner {
    display: inline-block;
    vertical-align: middle;

    img {
      max-width: 90vw;
      max-height: 80vh;
      // opacity: 0;
      transition: opacity .6s ease;

      @media (min-width: 768px) {
        max-width: 60vw;
        max-height: 70vh;
      }

      &.visibility--active {
        opacity: 1;
      }
    }

    p {
      margin-top: 20px;
      font-size: 18px;
    }
  }
}