body[data-page="home"] {
  color: white;

  .theme-container {
    background-color: $vibrantDark;
  }

  #nprogress .bar {
    background-color: white;
  }
}