.news {
  .wrapper {
    max-width: 90vw;
    margin: auto;
    width: 100% !important;

    @media (min-width: 768px) {
      max-width: 60vw;
    }
  }

  &__list {
    padding: 0 0 80px;
    list-style: none;
  }

  &__item {
    width: 100%;
    display: block;
    overflow: hidden;
    padding: 20px 0;
    border-bottom: 1px solid;

    @media (min-width: 768px) {
      padding: 40px 0;
    }

    &:last-child {
      border: 0;
    }

    p, h4 {
      span {
        transition: opacity .3s ease;
      }
    }

    &:hover {
      cursor: pointer;

      p, h4 {
        span {
          opacity: 0.4;
        }
      }
    }

    &__title {
      float: left;
      width: 100%;
      font-size: 22px;
      line-height: 1.1em;
      margin: 0 0 10px;

      @media (min-width: 768px) {
        width: 33.33%;
        font-size: 28px;
        margin: 0;
      }
    }

    &__summary {
      float: left;
      width: 100%;
      font-size: 22px;
      line-height: 1.1em;
      margin: 0;

      @media (min-width: 768px) {
        width: 66.66%;
        font-size: 28px;
      }
    }
  }
}