.similar-projects {
  position: relative;
  text-align: center;
  background-color: white;

  &.grid {
    @media (max-width: 768px) {
      padding: 40px 0 0;
    }
  }

  & > h2 {
    color: black !important;
    width: 100%;
    display: block;
    margin: 0 0 40px;

    @media (min-width: 768px) {
      margin: 0 0 80px;
    }
  }
} 