// Colour Schemes

// Projects theme
$projectsBackground: rgb(255, 255, 255);
$projectsLight: rgb(189, 0, 60);
$projectsDark: rgb(58, 3, 72);

// Rich
$richBackground: rgb(250, 235, 240);
$richLight: rgb(189, 0, 60);
$richDark: rgb(58, 3, 72);

// Warm
$warmBackground: rgb(255, 233, 225);
$warmLight: rgb(255, 18, 0);
$warmDark: rgb(98, 0, 20);

// Bright
$brightBackground: rgb(254, 241, 234);
$brightLight: rgb(254, 212, 48);
$brightDark: rgb(215, 48, 43);

// Vibrant
$vibrantBackground: rgb(230, 246, 252);
$vibrantLight: rgb(23, 219, 175);
$vibrantDark: rgb(22, 42, 122);

// Cool
$coolBackground: rgb(208, 250, 250);
$coolLight: rgb(0, 191, 227);
$coolDark: rgb(0, 45, 50);

// Formal Warm
$formalWarmBackground: rgb(242, 232, 228);
$formalWarmLight: rgb(211, 167, 121);
$formalWarmDark: rgb(30, 9, 17);

// Formal Cool
$formalCoolBackground: rgb(234, 233, 229);
$formalCoolLight: rgb(170, 187, 188);
$formalCoolDark: rgb(21, 27, 64);