::selection {
  background-color: black;
  color: white;
}

body {
  font-family: "Px Grotesk", sans-serif;
  font-weight: normal;
  font-size: 22px;
  margin: 0;
  color: black;
  background-color: #f8f8f8;
  // transition: color .4s cubic-bezier(.59,.01,.28,1);
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);

  @media (min-width: 768px) {
    font-size: 28px;
  }

  &.menu-is-open {
    @media (max-width: 768px) {
      header {
        .nav-icon {
          span:nth-child(1) {
            top: 18px;
            width: 0%;
            left: 50%;
          }
    
          span:nth-child(2) {
            transform: rotate(45deg);
          }
    
          span:nth-child(3) {
            transform: rotate(-45deg);
          }
    
          span:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
          }
        }

        .nav__wrapper {
          pointer-events: all;
  
          .nav__item {
            transform: translateX(0);
            transition: transform .4s cubic-bezier(.59,.01,.28,1);
          }
        }
      }

      main {
        opacity: 0.4;
        transition: opacity .4s cubic-bezier(.59,.01,.28,1) 0s;
      }
    }
  }
}

header {
  position: fixed;
  padding: 20px;
  width: calc(100% - 40px);
  top: 0;
  left: 0;
  z-index: 9999;
  transform: translateZ(0);
  transition: background-color .3s cubic-bezier(.59,.01,.28,1);

  @media (min-width: 768px) {
    padding: 20px 60px;
    width: calc(100% - 120px);
  }
}

footer {
  opacity: 0;
  transition: opacity .4s cubic-bezier(.59,.01,.28,1) 0s;
  background-color: white;
  color: black;
  margin: 0;
  padding: 40px 20px;
  width: calc(100% - 40px);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 0;

  &.is-visible {
    transition: opacity .4s cubic-bezier(.59,.01,.28,1) .4s;
    opacity: 1;
  }

  @media (min-width: 768px) {
    padding: 100px 60px 40px;
    width: calc(100% - 120px);
  }

  .row {
    display: block;
    overflow: hidden;

    @media (min-width: 768px) {
      &:first-child {
        margin-bottom: 100px;
      }
    }
  }

  .col {
    width: 100%;
    float: left;
    text-align: center;

    @media (max-width: 768px) {
      margin-bottom: 40px;
    }

    @media (min-width: 768px) {
      width: 33.33%;
    }

    p {
      margin: 0;
      font-size: 22px;

      a {
        padding-bottom: 0 !important;
        display: inline-block;
        line-height: 1.1em;
      }

      @media (min-width: 768px) {
        font-size: 28px;
      }
    }
  }

  p {
    margin: 0;
    line-height: 1.1em;

    &.footnote {
      text-align: center;
      width: 100%;

      @media (max-width:768px) {
        font-size: 14px;
        line-height: 1.1em;
      }

      a {
        display: inline-block;
        line-height: 1.1em;
        padding-bottom: 0px;
      }

      span {
        margin: 0 5px;
        display: inline-block;

        @media (min-width: 768px) {
          margin: 0 15px;
        }
      }
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: inherit;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
  padding-bottom: 1px;
  border-bottom: 2px solid transparent;
  outline: 0;
  // transition: color .3s ease, border .3s ease;

  &:hover, &.is-active {
    cursor: pointer;
    border-bottom: 2px solid;
  }
}

.theme-container {
  transition: background-color .3s cubic-bezier(.59,.01,.28,1);
  position: relative;
  z-index: 1;
}

main {
  // opacity: 0;
  transition: opacity .4s cubic-bezier(.59,.01,.28,1);
  margin-bottom: 359px;
  border-bottom: 1px solid #000;
  display: block;

  @media (min-width: 768px) {
    margin-bottom: 320px;
  }

  &.is-visible {
    // opacity: 1;
    transition: opacity .8s cubic-bezier(.59,.01,.28,1) .5s;
  }
}

.hidden {
  opacity: 0;
  font-size: 0;
}

.wrapper {
  padding: 0 20px; 
  max-width: 1320px;
  width: calc(100%);

  @media (min-width: 768px) {
    padding: 0 60px;
    width: calc(100% - 120px);
  }
}

.fullscreen-image {
  & > a {
    display: inline-block;
    border: 0 !important;
    vertical-align: middle;

    h2 {
      line-height: 1.1em;
      max-width: 90vw;
      margin: auto;

      @media (min-width: 768px) {
        max-width: 70vw;
      }

      span {
        border-bottom: 4px solid transparent;
        transition: opacity .3s ease;
      }
    } 

    &:hover {
      border: 0 !important;

      h2 {
        span {
          opacity: 0.6;
          // text-decoration: underline;
        }
      } 
    }
  }
}