.grid {
  width: 100%;
  margin: 0 auto;

  .touchevents &#projects {
    margin-top: -50px;
  }

  @media (min-width: 768px) {
    width: calc(100% - 100px);
    padding: 100px 50px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    &#projects {
      padding-top: 0;
      margin-top: -100px;
    }
  }

  &__item {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 10px);
      margin: 5px;
    }

    &-inner {
      position: relative;
      width: 100%;
      padding-bottom: 66.7796610169%;
      
      &::before {
        content: "";
        width: 0;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
      }

      &:hover {
        .grid__item__text {
          span {
            border-bottom: 2px solid;
          }
        }
      }
    }

    &__text {
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 30px;
      line-height: 1.1em;
      color: white;

      @media (min-width: 768px) {
        font-size: 3vw;
      }

      h3 {
        font-size: 14px;
        text-transform: uppercase;
        margin: -24px 0 10px 0;
        text-align: center;
        line-height: 1em;
    
        @media (min-width: 768px) {
          margin: -32px 0 10px 0;
          font-size: 18px;
        }
      }

      p {
        margin: 0 20px;
        text-align: center;

        @media (min-width: 768px) {
          margin: 0 1.5em;
        }
      }
    }
  }
}