.viewport-box {
  width: 100vw;
  padding: 20px 0;
  text-align: center;
  transition: background-color 1s ease, color .5s ease;

  @media (min-width: 768px) {
    &:not(.viewport-box--not-height) {
      height: calc(100vh - 40px);
    }
  }

  &:first-of-type, &.colour-change, &.brand-hero {
    height: calc(100vh - 40px);

    .touchevents & {
      height: calc(100vh - 90px);
    }
  }

  &--not-height:not(.colour-change) {
    margin: 20vh 0;
  }

  &.fullscreen-image {
    height: calc(100vh - 40px);

    @media (min-width: 768px) {
      margin: 0;
    }
  }

  &.fixed-bg {
    position: relative;
    background-color: #121212;

    &:after {
      content: "";
      background: inherit;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      z-index: 0;

      .no-touchevents & {
        background-attachment: fixed;
      }
    }
  }

  &:not(.viewport-box--no-pseudo-class) {
    &::before {
      content: "";
      display: inline-block;
      height: 100%;
      width: 0;
      vertical-align: middle;
    }
  }
}