.scroll-signal {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  z-index: 10;
  cursor: pointer;

  &:hover {
    p {
      border-bottom: 2px solid;
    }
  }

  p {
    border-bottom: 2px solid transparent;
    display: inline-block;
    margin: 0 0;
  }

  &__arrow {
    width: 28px;
    height: 28px;
    transform: rotate(45deg);
    border: 2px solid white;
    border-top: 0;
    border-left: 0;
    margin: 0 auto;

    @media (max-width: 768px) {
      width: 18px;
      height: 18px;
    }
  }
}