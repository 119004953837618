.formalCool-theme {
  color: $formalCoolDark;

  .theme-container {
    background-color: $formalCoolLight;
  }

  #nprogress .bar {
    background-color: $formalCoolDark;
  }

  // Nav 
  @media (max-width: 768px) {
    .nav__wrapper .nav__item {
      background-color: $formalCoolDark;
      color: $formalCoolLight;
    }
  
    .nav-icon span {
      background-color: $formalCoolDark;
    }

    .project-categories__list {
      li {
        background-color: $formalCoolDark;
        color: $formalCoolLight;
      }
    }

    .project-categories__button {
      background-color: $formalCoolDark;
      color: $formalCoolLight;
    }

    .project-categories__button .icon__inner span {
      background-color: $formalCoolLight;
    }
  }

  // Apply background tint to theme body
  &.background-tint--done {
    .theme-container {
      background-color: $formalCoolBackground;
    }
  }

  // Closing statement colour change
  &.colour-change--done {
    color: $formalCoolLight;

    .theme-container {
      background-color: $formalCoolDark;
    }

    #nprogress .bar {
      background-color: $formalCoolLight;
    }

    // Nav 
    @media (max-width: 768px) {
      .nav__wrapper .nav__item {
        background-color: $formalCoolLight;
        color: $formalCoolDark;
      }

      .nav-icon span {
        background-color: $formalCoolLight;
      }
    }
  }

  .swiper-scrollbar {
    background: none;

    &-drag {
      background-color: $formalCoolDark;
    }
  }
}