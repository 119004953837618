.swiper-container {
  width: 100vw;
  height: calc(100vh - 1px);
  overflow: hidden;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    .touchevents & {
      height: calc(100vh - 5rem);
    }
  }

  // @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) and (orientation : portrait) {
  //   height: 812px;
  // }

  // @media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (-webkit-device-pixel-ratio : 2) and (orientation : portrait) { 
  //   height: 667px;
  // }

  // @media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (-webkit-device-pixel-ratio : 2) and (orientation : portrait) {
  //   height: 736px;
  // }

  // @media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (-webkit-device-pixel-ratio : 2) and (orientation : portrait) {
  //   height: 568px;
  // }

  .swiper-slide {
    text-align: center;
    height: 150px;
    border: 0 !important;
    outline: 0 !important;

    @media (min-width: 768px) {
      height: 280px;
    }

    &:before {
      content: "";
      vertical-align: middle;
      display: inline-block;
      width: 0;
      height: 100%;
    }

    h2 {
      display: inline-block;
      vertical-align: middle;
      font-size: 30px;
      line-height: 1.1em;
      width: 90vw;
      margin: 0 auto;
      transition: color .3s cubic-bezier(.59,.01,.28,1), opacity .3s cubic-bezier(.59,.01,.28,1);
      opacity: .2;

      @media (max-width: 768px) {
        br {
          display: none;
        }
      }

      @media (min-width: 768px) {
        font-size: 4.6vw;
        width: 60vw;
      }
    }

    &-active {
      h2 {
        opacity: 1;
      }
    }
  }

  .swiper-scrollbar {
    top: 0;
    bottom: 0;
    height: 100%;
    right: 0;
    width: 6px;
    border-radius: 0;
    background-color: transparent !important;

    &-drag {
      right: 0;
      margin: 0;
      float: right;
      width: 6px;
      border-radius: 0;
    }
  }
}