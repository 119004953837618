.nav {
  padding: 0;

  &__item {
    float: left;
    margin-left: 30px;

    &--sitename {
      margin-left: 0;
    }

    h1 {
      display: inline-block;
    }
  }

  &__wrapper {
    float: right;

    @media (max-width: 768px) {
      position: absolute;
      top: 74px;
      left: 10px;
      width: calc(100% - 20px);
      pointer-events: none;

      .nav__item {
        width: 100%;
        display: block;
        margin: 3px 0;
        transition: transform .4s cubic-bezier(.59,.01,.28,1), background-color .8s cubic-bezier(.59,.01,.28,1);
        background-color: white;
        color: $vibrantDark;

        a {
          padding: 15px 10px;
          display: block;
          border: 0;
          
          span {
            border-bottom: 2px solid transparent;
          }

          &.is-active span {
            border-bottom: 2px solid;
          }
        }

        &:first-child {
          transform: translateX(calc(100% + 40px));
        }

        &:nth-child(2) {
          transform: translateX(calc(-100% - 40px));
        }

        &:nth-child(3) {
          transform: translateX(calc(100% + 40px));
        }
      }
    }
  }

  .nav-icon {
    display: none;

    @media (max-width: 768px) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      height: 34px;
      padding: 20px;
      cursor: pointer;

      span {
        background-color: white;
      }
    }

    &__inner {
      display: inline-block;
      line-height: 34px;
      width: 24px;
      height: 18px;
      position: relative;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      cursor: pointer;
    }

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
    }

    span:nth-child(1) {
      top: 0px;
    }

    span:nth-child(2), span:nth-child(3) {
      top: 9px;
    }

    span:nth-child(4) {
      top: 18px;
    }
  }
}